<template>  
<v-container fluid class="px-md-12 mt-16 pt-md-8">
  <v-row>
    <v-col 
      cols="12"
      md="6"
    >
      <v-col cols="12" md="10" class="pa-0">
        <p class="text-h6 mb-5">1. Confirm your order</p>
        <p v-if="cartItems.length === 0" class="text-center">There are no items currently in the cart.</p>
        <v-card 
          v-for="item in cartItems" 
          :key="item.id" 
          class="mb-6"
        >
          <v-row>
            <v-col cols="4" md="3" class="py-0 pr-0">
              <v-img :src="item.image_url" height="100%" aspect-ratio="1"></v-img>
            </v-col>
            <v-col class="px-0">
              <v-card-title>
                {{ item.name }}
              </v-card-title>
              <v-card-subtitle>
                Subtotal: $ {{ parseFloat(item.amount).toFixed(2) }}
              </v-card-subtitle>
              <v-card-actions>
                <v-btn color="primary" @click="updateCart(item, item.qty--)" :disabled="item.qty == 1" icon><v-icon>mdi-minus-circle-outline</v-icon></v-btn>
                <span>{{ item.qty }}</span>
                <v-btn color="primary" @click="updateCart(item, item.qty++)" class="ma-0" icon><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-btn color="red" icon @click="removeFromCart(item.id)"><v-icon>mdi-delete-outline</v-icon></v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="10" align="end" class="px-0">
        <v-divider class="mb-1"></v-divider>
          <span>Total:<span class="text-h5"> $ {{ parseFloat(cartTotal).toFixed(2) }}</span></span>
        <v-divider class="mt-1"></v-divider>
      </v-col>
    </v-col>
    <v-divider vertical></v-divider>
    <v-col
      cols="12"
      md="6"
      class="pl-md-12"
    >
      <p class="text-h6 mb-5">2. Submit your order!</p>
      <v-form v-model="valid" @submit.prevent="createOrder">
        <v-text-field
          placeholder="Name"
          v-model="name"
          :rules="nameRules"
          required
          outlined
          dense
          color="primary"
          class="mb-4"
          hide-details="auto"
        ></v-text-field>
        <v-text-field
          placeholder="Phone number"
          v-model="number"
          :rules="numberRules"
          required
          outlined
          dense
          color="primary"
          class="mb-4"
          hide-details="auto"
        ></v-text-field>
        <v-menu
          v-model="dateDialog"
          :close-on-content-click="false"
          persistent
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedDateAndTime"
              placeholder="Delivery slot"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :rules="dateRules"
              required
              outlined
              dense
              @click="fetchSlots"
              color="primary"
              class="mb-4"
              hide-details="auto"
            ></v-text-field>
          </template>
          <v-stepper v-model="e1" color="primary" min-width="290">
            <v-stepper-items>
              <v-stepper-content step="1" class="pa-0">
                <v-date-picker 
                  v-model="date"
                  header-color="primary"
                  color="secondary"
                  :allowed-dates="getAllowedDates"
                  :min="new Date().toISOString()"
                  reactive
                  :picker-date.sync="pickerDate"
                  @click:month="fetchSlots"
                  @change="fetchSlots"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="dateDialog = false, date=''"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="getTimeSlots(formattedDate)"
                    :disabled="date==='' || slotsLoading"
                    :loading="slotsLoading"
                  >
                    Pick Slot
                    <v-icon
                    >
                      $next
                    </v-icon>
                  </v-btn>
                </v-date-picker>
              </v-stepper-content>
              <v-stepper-content step="2" class="pa-0">
                <v-card>
                  <v-card-title class="primary text-h4 white--text pa-6">
                    {{ formattedDate }}
                  </v-card-title>
                  <v-card-text class="pa-6 overflow-y-auto card-height">
                    <v-radio-group v-model="radioGroup">
                      <v-radio
                        v-for="slot in timeSlots"
                        :key="slot.id"
                        class="font-weight-bold radio-item rounded pa-3"
                        active-class="selected-item"
                        :value="slot"
                        :disabled="slot.is_full"
                      >
                        <template v-slot:label>
                          <v-row class="pa-0 ma-0 black--text">
                            <v-col cols="3" class="pa-0" align="end">
                              <span>{{ slot.slot_time.split('-')[0] }}</span> 
                            </v-col>
                            <v-col cols="1" class="pa-0 mx-1" align="end">
                              <span>-</span> 
                            </v-col>
                            <v-col cols="3" class="pa-0" align="end">
                              <span>{{ slot.slot_time.split('-').pop() }}</span>
                            </v-col>
                            <v-col align="end" class="pa-0">
                              <span :class="slot.is_full ? 'red--text': 'green--text'">{{ slot.booking_count }} / {{ slot.capacity }}</span>
                            </v-col>
                          </v-row>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="radioGroup=null, e1=1"
                    >
                      <v-icon>
                        $prev
                      </v-icon>
                      Pick Date
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="dateDialog = false, e1=1"
                      :disabled="radioGroup===null"
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-card>        
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-menu>
        <v-select
          placeholder="Delivery method"
          v-model="delivery"
          :items="deliveryItems"
          :rules="deliveryRules"
          required
          outlined
          dense
          class="mb-4"
          color="primary"
          hide-details="auto"
        ></v-select>
        <template v-if="delivery == 'Delivery'">
          <p>Delivery Terms and Conditions</p>
          <ul>
            <li>Runner payment is not included in the Pancit's order payment.<br><em>Pembayaran untuk runner TIDAK TERMASUK dalam pembayaran kuih pancit.</em></li>
            <li>Runner will contact you upon his/her arrival (for his/her payment details).<br><em>Runner akan menghubungi kita sebelum bisdia menghantar kuih kita + pembayaran.</em></li>
            <li>Pancit will be delivered by runner.<br><em>Kuih pancit akan dihantar oleh runner.</em></li>
            <li>The Pancit will assign the runner.<br><em>Runner akan diaturkan oleh The Pancit.</em></li>
            <li>Delivery Brunei-Muara only.<br><em>Runner hantar dalam kawasan Brunei-Muara sahaja.</em></li>
            <li>Runner charge starts at $5.<br><em>Bayaran untuk runner bermula dari $5.</em></li>
            <li>Runner delivery 8 am - 4 pm.<br><em>Servis penghantaran runner dari jam 8 pagi hingga 4 petang sahaja.</em></li>
            <li>Runner sets the price.<br><em>Runner yang menentukan harga cas penghantaran.</em></li>
          </ul>
          <v-checkbox 
            v-model="checkbox" 
            label="I have read and understood the delivery terms and conditions"
            color="primary"
          ></v-checkbox>
          <v-textarea
            placeholder="Delivery address"
            v-model="address"
            :rules="addressRules"
            :disabled="delivery == 'Self pickup' || !checkbox"
            required
            outlined
            dense
            color="primary"
            class="mb-4"
            rows="3"
            auto-grow
            hide-details="auto"
          ></v-textarea>
        </template>
        <v-select
          placeholder="Payment method"
          v-model="payment"
          :items="paymentItems"
          :rules="paymentRules"
          required
          outlined
          dense
          class="mb-4"
          color="primary"
          hide-details="auto"
        ></v-select>
        <v-textarea
          placeholder="Special requests"
          v-model="notes"
          outlined
          dense
          color="primary"
          class="mb-4"
          rows="3"
          auto-grow
        >
        </v-textarea>
        <v-btn 
          :loading="loading"
          depressed
          color="primary"
          class="text-white px-4"
          block
          type="submit"
          :disabled="!valid || (cartItems.length === 0)"
        >
          Place Order
        </v-btn>
      </v-form>
    </v-col>
  </v-row>
</v-container>
</template>


<script>
import apiService from '@/services/api.service.js'
export default {
  data () {
    return {
      valid: false,
      loading: false,
      slotsLoading: false,
      name: '',
      number: '',
      delivery: null,
      payment: null,
      checkbox: false,
      address: '',
      notes: '',
      deliveryItems: [ 'Delivery', 'Self pickup' ],
      paymentItems: [ 'BIBD transfer', 'Cash' ],
      nameRules: [
        value => !!value || 'Name is required.'
      ],
      deliveryRules: [
        value => !!value || 'Delivery method is required.'
      ],
      addressRules: [
        value => !!value || 'Address is required.'
      ],
      paymentRules: [
        value => !!value || 'Payment method is required.'
      ],
      dateRules: [
        value => !!value || 'Date is required.',
      ],
      numberRules: [
        value => !!value || 'Phone number is required.',
        value => !!(/^\d{7}$/.test(value)) || 'Phone number must be valid.',
      ],
      date: '',
      pickerDate: new Date().toISOString().split('T')[0].substring(0,7),
      slots: [],
      slotDates: [],
      timeSlots: [],
      radioGroup: null,
      e1: 1,
      dateDialog: false
    }
  },
  methods: {
    removeFromCart(id) {
      this.$store.dispatch('removeFromCart', {product: id})
    },
    updateCart(item) {
      this.$store.dispatch('updateCart',{product:item})
    },
    getAllowedDates(val) {
      const [year, month, day] = val.split('-')
      const date = `${day}/${month}/${year}`
      if (this.slotDates.indexOf(date) !== -1) {
        return true
      } else return false
    },
    async fetchSlots() {
      if (this.date != '') this.slotsLoading = true
      this.slots = []
      try {
        const res = await apiService.get('api/v1/slots', { 
          year: new Date(this.pickerDate).getFullYear(),
          month: new Date(this.pickerDate).getMonth()+1
        })
        const response = res.data
        this.slots = response.data
        this.slotDates = [...new Set(this.slots.map(slot => slot.slot_date))]
        this.slotsLoading = false
      }
      catch(error) {
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue retrieving the slots at this moment. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    getTimeSlots(day) {
      this.timeSlots = this.slots.filter((o) => o.slot_date === day)
      this.e1 = 2
    },
    async createOrder() {
      this.loading = true
      const items = this.cartItems.map(({ id, qty, price, amount }) => ({
        menu_id: id,
        quantity: qty,
        unit_price: price,
        amount: amount
      }));
      let orders = {
        order_date: this.date,
        order_status_id: 1,
        slot_id: this.radioGroup.id,
        customer_name: this.name,
        customer_number: this.number,
        delivery_method: this.delivery,
        delivery_address: this.address,
        payment_method: this.payment,
        notes: this.notes,
        discount: "0",
        total: this.cartTotal,
        order_items_attributes: items
      }
      try {
        const res = await apiService.create('/api/v1/orders', { orders })
        this.loading = false
        const response = res.data
        this.$store.dispatch('removeCart')
        let data = JSON.stringify(response.data)
        this.$router.push({ name: 'success', params: { data: data } })
      }
      catch(error) {
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue placing the order. Please try again at a later time', 
            status: 'error' 
          })
      }
    }
  },
  computed : {
    cartItems () {
      return this.$store.state.cart.items
    },
    cartTotal () {
      return this.$store.state.cart.cartTotal
    },
    formattedDate() {
      if (!this.date) return null
      const [year, month, day] = this.date.split('-')

      return `${day}/${month}/${year}`
    },
    formattedDateAndTime() {
      if (!this.date || !this.radioGroup) return null
      const [year, month, day] = this.date.split('-')
      return `${day}/${month}/${year}  (${this.radioGroup.slot_time})`
    }
  },
  watch: {
    pickerDate() {
      this.fetchSlots()
    }
  }
}
</script>

<style lang="scss">
  .radio-item {
    background-color: #fafafa;
  }
  .selected-item {
    border: 1px solid #A78FB3;
  }
  .v-radio--is-disabled {
    opacity: 0.5;
  }
  .card-height {
    height: 290px;
  }
</style>